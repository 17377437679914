import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import HeaderSection from '../../../../common/HeaderSection';
import TabContent from './TabContent';
import { ResourcesHero, CheckCircle } from '../../../../ImagePaths';

const sectionHeader = {
  image: ResourcesHero,
  title: 'Retirement resources',
  caption: `
    <p class="fs-display5">
      Managing and servicing client needs is a full-time job. Save
      time with these resources to help you educate small business
      clients and make the most of your opportunities in this market.
    </p>
  `,
  alt: 'Employee in a meeting.',
  copy: ``,
};

const tabsAbout = {
  intro: `
  `,
  resourceCards: [
    {
      id: 2,
      headline:
        'Simply Retirement by Principal<sup><small>®</small></sup> - Financial professional brochure (Bundled)',
      date: '',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736V&ty=VOP',
    },
    {
      id: 1,
      headline:
        'Simply Retirement by Principal<sup><small>®</small></sup> - Financial professional brochure (TPA)',
      date: '',
      link: 'https://secure02.principal.com/publicvsupply/GetFile?fm=PQ12736&ty=VOP',
    },
    {
      id: 0,
      headline:
        'Help grow your wealth management practice using 401(k) start-up plans',
      date: '',
      link: 'https://advisors.principal.com/wps/portal/advisor/news-trends/news/article/help-grow-your-wealth-management-practice-using-401k',
    },
  ],
};

const Resources = () => (
  <div className="resources-page mb-5">
    <HeaderSection
      image={sectionHeader.image}
      title={sectionHeader.title}
      caption={sectionHeader.caption}
      copy={sectionHeader.copy}
      alt={sectionHeader.alt}
    />

    <section
      className="section section_py-5"
      style={{ marginTop: '-70px', zIndex: '1' }}
    >
      <div className="container-xl px-0">
        <TabContent
          intro={tabsAbout.intro}
          resourceCards={tabsAbout.resourceCards}
        />
      </div>
    </section>
    <section className="section section_py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <h3 className="fs-h3 text-center mb-5">
              See what Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              has to offer.
            </h3>
          </div>
        </div>
        <div className="row d-print-none">
          <div className="col-lg-7 mx-auto">
            <p className="fs-h6 text-center mb-5">
              Watch this video demo and see how we make it easy to design and
              manage retirement plans for small businesses.
            </p>
            <ReactPlayerLoader
              accountId="1510329453001"
              videoId="6274775432001"
              attrs={{
                className: 'brightcove-player',
              }}
              embedOptions={{ responsive: { aspectRatio: '16:9' } }}
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section section_py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <img
              src={CheckCircle}
              alt="Checkmark In Circle Icon"
              className="d-block mx-auto mb-3"
            />
            <h3 className="fs-h3 text-center mb-4">Ready to get started?</h3>
            <p className="text-center pb-5 mb-5">
              <Link to="/plans" className="srp-button srp-button__primary">
                Create a proposal
              </Link>
            </p>
            <p className="fs-disclaimer text-center pb-5 mb-5">
              Intended for financial professional and third party administrator
              use.
            </p>
            <span className="d-none">1178600-052020</span>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Resources;
