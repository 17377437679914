import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import FAQ from '../../../common/Faq';
import { FaqHeroImage } from '../../../ImagePaths';
import HeaderSection from '../../../common/HeaderSection';
import SectionSingleCol from '../../../common/SectionSingleCol';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../Redux/FormDataRedux';
// import { asOfDate } from '../../../Services/utilities';

const defaultSectionHeader = {
  image: FaqHeroImage,
  title: 'Frequently asked questions',
  caption: `
      <p class="fs-display5">
        Looking for more details? Here are answers to some of the questions you may have about this retirement plan solution.
      </p>
    `,
  alt: 'Man working on a computer.',
  copy: ``,
};

const faqDisclaimer1 = `<p class="fs-disclaimer">* If an employee takes a distribution (“cashes out”) before reaching age 59 1⁄2, the IRS generally requires automatic withholding of 20% of a 401(k) early withdrawal, which will be used to offset what they may owe for the applicable year in federal taxes. They may owe applicable state and local taxes as well. The IRS will also assess a 10% early withdrawal penalty if applicable.</p>`;
const faqDisclaimer2 = `<p class="fs-disclaimer">* For the first three years, Ubiquity Retirement + Savings will cover the cost of an ERISA fidelity bond up to $250,000 in assets (which equals a $25,000 bond). After three years, a renewal letter will be sent, and the business owner can choose to accept the renewal at their own expense or consult with their financial professional and/or TPA in choosing another bond provider.</p>`;

const updatedSectionEnrollmentFees = `
<h4 class="fs-h3 my-3 pt-5">Enrollment and fees</h4>

<p class="fs-h2 text-purple"><strong>Why do employees get automatically enrolled in the plan?</strong></p>
<p class="fs-p mb-4">
  The Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan requires employees to be automatically enrolled at a default pre-tax contribution percentage set by you. 
  Per the SECURE 2.0 Act of 2022, new plans after December 31, 2024, are required to automatically enroll participants - see SECURE 2.0 Act for more details. 
  The default contribution rate must be at least 3%, but not more than 10%, plus an automatic contribution increase of 1% per year up to a maximum of at least 10%, non-safe harbor plan, but not more than 15% if plan is safe-harbor. 
  Participants can change this amount or opt out by choosing the opt-out option in their online account under “Manage Deferrals.” 
  With the automatic enrollment feature, it can help increase participation, simplify administration, and help employees save for retirement.
</p>

<p class="fs-h2 text-purple"><strong>What happens if the company grows beyond 100 employees?</strong></p>
<p class="fs-p mb-4">
  When eligible employees exceed 100, the plan may become subject to an independent annual plan audit. 
  When the plan becomes subject to audit requirements, Ubiquity will inform you and work alongside you and the auditors to complete this annual audit.
</p>

<p class="fs-h2 text-purple"><strong>What if an employee leaves their job? Is there a penalty?</strong></p>
<p class="fs-p mb-4">
  If an employee leaves your client’s business, they’ll need to decide what they want to do with their vested 401(k) account. 
  They can keep the money in the plan, move it to a new employer’s plan, move it to a self-directed retirement account (also known as a rollover IRA), or <button id="faq-disclaimer-1" class="text-underline p-0 no-border-button fs-h6">cash out.<sup><small>*</small></sup></button> 
  Former employees may stay invested in the 401(k) plan indefinitely if their balance is at least $5,000.
</p>

<p class="fs-h2 text-purple"><strong>Do business owners need to participate?</strong></p>
<p class="fs-p mb-4">
  No. However, it’s a great way to receive the same benefits as their employees. 
  For example, they can defer paying taxes on any pre-tax contributions they make, and they can take advantage of any matching contributions that are offered by the company. 
  Business owners may contribute up to the maximum per IRS limits for the applicable tax year. 
  Note: They’ll be automatically enrolled unless they opt out by choosing the opt-out option in their online account.
</p>

<p class="fs-h2 text-purple"><strong>What are the plan fees, and who pays them?</strong></p>
<p class="fs-p"><strong>The business owner pays:</strong></p>
<ul>
  <li>
    <p class="fs-p mb-0"><strong>$500 one-time</strong> setup fee for bundled plans.</p>
  </li>
  <li>
    <p class="fs-p mb-0"><strong>$145 per month</strong> recordkeeping fee ($435 billed quarterly) if working with a third party administrator (TPA) or $185 per month bundled recordkeeping fee ($555 billed quarterly) if not working with a TPA.</p>
  </li>
</ul>

<p class="fs-p"><strong>The participants pay:</strong></p>
<ul>
  <li>
    <p class="fs-p"><strong>$6 per participant, per month</strong> recordkeeping fee for each participant with an account balance over $100. This is deducted from participant accounts monthly. The business owner may elect to pay it quarterly on behalf of the participants.</p>
  </li>
  <li>
    <p class="fs-p"><strong>Financial professional and/or TPA fees,</strong> if you choose to work with one. If the financial professional and/or the TPA chooses to be paid out of plan assets, this fee will be deducted quarterly from participant balances in the plan on a pro-rated basis.</p>
  </li>
  <li>
    <p class="fs-p"><strong>Custodial and investment fees,</strong> which are charged against participating employees’ accounts and vary by investment.</p>
  </li>
</ul>

<p class="fs-h2 text-purple"><strong>How do contributions work?</strong></p>
<p class="fs-p mb-4">
  Contributions are forwarded by the plan sponsor for the employees (participants). While employee contributions are elective to the employee, some employer contributions may be required by the terms of their plan.
</p>
<ul>
  <li><p class="fs-p"><strong>Participants</strong> contribute their elected deferral each pay period.</p></li>
  <li><p class="fs-p">If offered, <strong>business owners</strong> can match up 100% up to 10% of eligible pay participants contribute for the plan each pay period.</p></li>
</ul>`;

const updatedSectionGettingForFees = `
  <p class="fs-h2 text-purple">
    <strong>What exactly is the business owner getting for the fees?</strong>
  </p>
  <p class="fs-p mb-4">Recordkeeping fees cover the cost of maintaining the plan. This includes services such as:</p>
  <ul>
    <li>
      <p class="fs-p">Annual plan compliance testing</p>
    </li>
    <li>
      <p class="fs-p">Government filing and reporting of required documentation</p>
    </li>
    <li>
      <p class="fs-p">Ongoing recordkeeping (e.g., tracking which employees are participating and the amount they’ve invested in each of the plan investment options)</p>
    </li>
    <li>
      <p class="fs-p">Automatic participant signup and onboarding</p>
    </li>
    <li>
      <p class="fs-p">An online platform for plan administration and participant account management 24/7</p>
    </li>
    <li>
      <p class="fs-p">An investment option lineup selected and managed by Wilshire Advisors LLC, a registered 3(38) investment fiduciary</p>
    </li>
    <li>
      <p class="fs-p">A dedicated phone number for questions</p>
    </li>
    <li>
      <p class="fs-p">Bond (insurance) coverage of the plan’s assets up to <button id="faq-disclaimer-2" class="no-border-button text-underline p-0">$250,000</button><small>*</small></sup></p>
    </li>
    <li>
      <p class="fs-p">Access to a comprehensive financial wellness platform that provides tools and resources to employees to better manage their current and future financial well-being.</p>
    </li>
    <li>
      <p class="fs-p">Business owner access to Elevate by Principal</p>
    </li>
  </ul>
  <p class="fs-p mb-4">Investment fees cover the cost of managing the investments, including operating expenses, management fees, and administrative fees. Custodial fees cover the services of the financial custodian, such as holding plan assets in an account for safekeeping, collecting dividend and interest income, and providing account statements.</p>
  <p class="fs-disclaimer">* For the first three years, Ubiquity Retirement + Savings will cover the cost of an ERISA fidelity bond up to $250,000 in assets (which equals a $25,000 bond). After three years, a renewal letter will be sent and the business owner can choose to accept the renewal at their own expense or consult with their financial professional and/or TPA in choosing another bond provider.</p>
`;

const defaultSectionCreatePlan = `
<h4 class="fs-h3 my-3 pt-5">
  Creating a plan design/proposal
</h4>
<p class="fs-h2 text-purple"><strong>How do I create or start a Simply Retirement by Principal® 401(k) plan for my client?</strong></p>
<p class="fs-p mb-4">
  To create or start a plan for your client you and/or your TPA will begin by answering a few simple questions regarding your business. Then, make selections on plan design as well as choose an investment lineup. This will then be sent to the business owner for review and purchase. If you’re working with a TPA, they’ll assist with the plan design. Either you or the TPA can send the plan to the business owner for review and purchase. The plan proposal you create can be saved, shared, and edited up to 60 days after it was created. Keep in mind that financial professionals, TPAs, and business owners can create a plan design, but <strong>only a business owner can complete the purchase.</strong>
</p>
<p class="fs-h2 text-purple"><strong>How long does it take to answer the questions and create a plan/proposal?</strong></p>
<p class="fs-p mb-4">
  On average, 15 to 20 minutes is a reasonable estimate, but your experience may vary. You may need to take some time to look up information, consult another person on your team, or think about some of your selections in the process.
</p>
<p class="fs-p mb-4">
  The Simply Retirement by Principal<sup><small>®</small></sup> financial professionals dashboard includes proposal management. It allows you to easily manage and track the status of each proposal and create new proposals all from one place. You’ll be able to see all your active proposals in the dashboard by clicking “View more” on the active proposals bar chart. You can also get there by clicking the proposals tab on the left-hand side of your dashboard homepage. Plan designs are saved for 60 days.
</p>
<p class="fs-h2 text-purple"><strong>How do I find a TPA?</strong></p>
<p class="fs-p mb-4">
  If you aren’t engaged with a TPA, but are interested in working with one, please contact the Retirement Sales Support Team at <a href="mailto:RetirementSalesSupportTeam@exchange.principal.com">RetirementSalesSupportTeam@exchange.principal.com</a> for assistance.
</p>
<p class="fs-h2 text-purple"><strong>Is there someone I can contact if I have questions?</strong></p>
<p class="fs-p mb-4">
  Yes. You can call Ubiquity’s dedicated phone number: <a href="tel:8448040604">844-804-0604</a>, or email <a href="mailto:info@simplyretirement.com">info@simplyretirement.com</a>.
</p>
<p class="fs-h2 text-purple"><strong>What credentials does Simply Retirement by Principal<sup><small>®</small></sup> require for financial professionals to receive compensation through the retirement plan?</strong></p>
<p class="fs-p mb-4">
  Simply Retirement by Principal<sup><small>®</small></sup> is a group variable annuity product offering which requires both FINRA registration and variable annuity line insurance licensing for you to receive compensation through the retirement plan. Principal will research required financial professional licensing and registration beginning with the National Producer Number (NPN) which all variable annuity insurance licensed professionals are assigned.
</p>
<p class="fs-h2 text-purple"><strong>Who handles testing and reporting?</strong></p>
<p class="fs-p mb-4">
  Ubiquity's team of plan compliance individuals perform annual nondiscrimination testing. Ubiquity also helps business owners stay on top of annual government form filings and documentation—like IRS Form 5500, 8955-SSA preparation and filing, participant disclosures, and annual plan notifications.
</p>
`;

const defaultSectionPlanFeatures = `
<h4 class="fs-h3 my-3 pt-2">
  Plan features
</h4>
<p class="fs-h2 text-purple"><strong>What’s the value of a 401(k) plan to employees?</strong></p>
<p class="fs-p mb-4">
  First and foremost, it gives them an easy way to save for retirement. Contributions are automatically deducted from employee paychecks each pay period, so they don't have to budget separately for setting money aside. They also have an opportunity to take advantage of any matching contributions offered, making it possible for them to save more. Plus, pre-tax contributions are deducted from paychecks before income taxes, reducing individual taxable income, and taxes are deferred on any investment gains and earnings until the money is withdrawn in retirement.
</p>
<p class="fs-h2 text-purple"><strong>Do participants have the option to defer Roth contributions with this plan?</strong></p>
<p class="fs-p mb-4">
  Yes. If selected, business owners may choose to offer participants the ability to make Roth contributions in addition to pre-tax deferrals with this plan. If you’re working with a TPA, your plan provisions for auto enrollment and Roth contributions may vary.
</p>
<p class="fs-h2 text-purple"><strong>What’s the vesting schedule for this plan?</strong></p>
<p class="fs-p mb-4">
  Business owners can choose to have their employees 100% vested in the 401(k) plan immediately or on a 6-year graded vesting schedule. However, if you’re working with a TPA, your plan’s vesting options may vary.
</p>
<p class="fs-h2 text-purple"><strong>Are loans available with this plan?</strong></p>
<p class="fs-p mb-4">
  Yes. If the business owner chooses to offer loans, participants can request to take one loan from their 401(k) plan account balance and select a loan repayment schedule that best suits them—but is no longer than five years. Participants may repay a loan to up to 30 years if the proceeds are used to purchase their primary residence. Only one loan may be outstanding at a time. Loan repayments are made via after-tax payroll deductions. The interest portion of the loan payment is applied to the participant’s account. If you’re working with a TPA, the plan may vary.
</p>
<p class="fs-h2 text-purple"><strong>Are hardship withdrawals available with this plan?</strong></p>
<p class="fs-p mb-4">
  Yes. Business owners can choose to have the plan allow for hardship withdrawals. This provision allows participants to withdraw eligible amounts from their 401(k) account to help meet the needs of a financial hardship. Participants must pay taxes on the amount they withdraw, plus a 10 percent penalty if they are under age 59 1⁄2. Additional requirements may apply based on the provisions of the plan.
</p>
<p class="fs-h2 text-purple"><strong>How can I get more information on the investments?</strong></p>
<p class="fs-p mb-4">
  See <a href="/financial-professionals/investments">this page</a>. There’s a listing of investment options under each asset class, and you can click any investment option for fact sheets and other details.
</p>
`;

const defaultSectionOnBoarding = `
<h4 class="fs-h3 mb-3">
  Onboarding
</h4>
<p class="fs-h2 text-purple">
  <strong>
    What happens after the business owner commits to purchasing the plan? How will plan implementation work?
  </strong>
</p>

<p class="fs-p mb-4">
  When the business owner clicks the “Complete your purchase” button, they’ll be redirected to the Ubiquity Retirement + Savings® (Ubiquity) website where they’ll:
</p>

<div class="container container-fluid">
  <div class="row my-5 justify-content-center">
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Set up login credentials.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Provide additional details about their business.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Pay the one-time non-refundable $500 setup fee.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Sign required documents electronically.
      </p>
    </div>
  </div>
</div>
<p class="fs-p">
  After completing these steps, you’ll enter the “Implementations” stage where you’ll work with your dedicated specialist at Ubiquity to set up the plan over 3 phone calls. Here’s what you can expect:
</p>

<ul>
  <li class="fs-p">The business owner will get an email from “401(k) Implementations” <<a href="mailto:newplan@myubiquity.com">newplan@myubiquity.com</a>> to set up a 50-minute introductory call with their dedicated Ubiquity specialist. The call should be scheduled as soon as possible, preferably within the next five business days.</li>
  <li class="fs-p">The Ubiquity specialist will help them add their employees on the 2nd call. </li>
  <li class="fs-p">The Ubiquity specialist will assist them in the process of funding the first contribution on the 3rd and final call. After the plan sponsor processes the first payroll and starts funding the plan, they'll be all set and the plan is active.</li>
</ul>


<p class="fs-p mb-4">
Watch for an email invitation when you save your first Simply Retirement by Principal<sup><small>®</small></sup> plan proposal. The email will include a link to the Ubiquity Retirement + Savings<sup><small>®</small></sup> website, where you can:

<div class="container container-fluid">
  <div class="row my-5 justify-content-center">
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
       Register with Ubiquity and establish login credentials.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Check out your dashboard to help manage proposals.
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Upload Summary Plan Description (SPD) for the new plan (TPAs only).
      </p>
    </div>
    <div class="col-md-1 align-self-center">
      <i class="fa-solid fa-arrow-right text-link" aria-hidden ></i>
    </div>
    <div class="col-md-2 align-self-center">
      <p class="fs-p lh-125 fw-bold text-purple">
        Manage your Simply Retirement by Principal<sup><small>®</small></sup> clients.
      </p>
    </div>
  </div>
</div>

  <p class="class="fs-p">You’ll also be copied on key email notifications to your clients as they complete the purchase and plan setup process.</p>
</p>

<p class="fs-h2 text-purple"><strong>What payroll companies will you have integration with and how will they get set up?</strong></p>
<p class="fs-p">
  Ubiquity offers 360-integration for most client’s payroll providers with a cloud-based payroll system through Payroll (K)oncierge<sup><small>TM</small></sup> and Enhanced Payroll services. Please visit <a href="<LINK>">Payroll Integration - Ubiquity</a> for more information regarding Ubiquity’s payroll services.
</p>

<p class="fs-h2 text-purple"><strong>Who’ll help the plan sponsor with the first payroll submission?</strong></p>
<p class="fs-p">
  As part of the implementation process, a Ubiquity team member serves as the Payroll (K)oncierge<sup><small>TM</small></sup> and meets virtually with the business owner to review integration options, provides simple instructions, and assists them with their first payroll submission. There is no additional fee for this service as this is part of the Simply Retirement by Principal<sup><small>®</small></sup> plan sponsor experience.
</p>
`;

const defaultSectionSupport = `
<h4 class="fs-h3 my-3 pt-5">
  Support
</h4>
<p class="fs-h2 text-purple"><strong>How can I get support to set up my client’s plan?</strong></p>
<p class="fs-p mb-4">
  The Simply Retirement by Principal<sup><small>®</small></sup> financial professional dashboard is a great place to start where you can access implementation links. If you have questions on the dashboard, require a new registration invitation, or are having trouble logging into your dashboard, please contact Ubiquity Retirement and Savings at <a href="tel:8557005569"><u>855-700-5569</u></a>, Monday–Friday, 6 a.m.–5 p.m. PT or <a href="mailto:simplysupport@myubiquity.com">simplysupport@myubiquity.com</a>.
</p>

<p class="fs-h2 text-purple"><strong>Implementation support</strong></p>
<p class="fs-p">
  <a href="mailto:newplan@myubiquity.com">newplan@myubiquity.com</a><br>
  <a href="tel:8559104015"><u>855-910-4015</u></a><br>
  Monday–Friday, 6 a.m.–5 p.m. PT
</p>

<p class="fs-h2 text-purple"><strong>How do I get support after my plan is set up?</strong></p>
<p class="fs-p mb-4">The following Customer Success Teams are available:</p>

<p class="fs-h2 text-purple"><strong>Plan sponsor support</strong></p>
<p class="fs-p">
  <a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a><br>
  <a href="tel:8557005569"><u>855-700-5569</u></a><br>
  Monday–Friday, 6 a.m.–5 p.m. PT
</p>

<p class="fs-h2 text-purple"><strong>Participant support</strong></p>
<p class="fs-p">
  <a href="mailto:info@myubiquity.com">info@myubiquity.com</a><br>
  <a href="tel:8554014357"><u>855-401-4357</u></a>, Option 1<br>
  Monday–Friday, 6 a.m.–5 p.m. PT
</p>

<p class="fs-h2 text-purple"><strong>Financial professional support</strong></p>
<p class="fs-p">
  <a href="mailto:SimplySupport@myubiquity.com">SimplySupport@myubiquity.com</a><br>
  <a href="tel:8557005569"><u>855-700-5569</u></a><br>
  Monday–Friday, 6 a.m.–5 p.m. PT
</p>

<p class="fs-h2 text-purple"><strong>TPA support</strong></p>
<p class="fs-p">
  <a href="mailto:TPAsupport@myubiquity.com">TPAsupport@myubiquity.com</a><br>
  <a href="tel:8557005569"><u>855-700-5569</u></a><br>
  Monday–Friday, 6 a.m.–5 p.m. PT
</p>

<p class="fs-p mb-4"><strong>Intended for Financial Professional use only.</strong></p>
`;

const defaultSectionGeneralInformation = `
<h4 class="fs-h3 mb-3">
  General Information
</h4>
<p class="fs-h2 text-purple">
  <strong>What is Simply Retirement by Principal<sup><small>®</small></sup>?</strong>
</p>
<p class="fs-p mb-4">
  Simply Retirement by Principal<sup><small>®</small></sup> is a product for startup plans with fewer than 100 employees. It’s designed to offer an easy-to-use, fully online experience that removes some of the friction that can occur with traditional onboarding—including chasing down paperwork, getting answers to service and expense questions, finalizing the investment offering, and selecting a fidelity bond. Our goal is to simplify and streamline this process, so you can focus on delivering value to your clients.
</p>
<p class="fs-p mb-4">
  Backed by experience, this product combines more than 80 years of retirement expertise from Principal<sup><small>®</small></sup> with the digital technology and services of Ubiquity Retirement + Savings<sup><small>®</small></sup>. We’ve come together to help make retirement plans a reality for more small businesses.
</p>
<p class="fs-h2 text-purple">
  <strong>Why did Principal work with Ubiquity to create a digital product, and what does this term mean?</strong>
</p>
<p class="fs-p mb-4">
  When we refer to Simply Retirement by Principal<sup><small>®</small></sup> as a digital product, it means that everything is online, from designing the plan, to onboarding and administration. Our focus is on overcoming three obstacles that small business owners typically face as to why they don’t set up a retirement plan today—cost, complexity, and time.
</p>
<p class="fs-p mb-4">
  We <span class="text-purple"><b>worked with Ubiquity to</b></span> create a digital product for several reasons. One of our goals was to provide a simple strategy for small businesses to establish a 401(k) plan and help their employees save for retirement—helping to address a need for the thousands of small businesses who don’t currently offer their employees a retirement savings plan. We also wanted to provide a simplified retirement plan platform for financial professionals to quote and establish small business retirement plans efficiently and easily—so they can spend less time managing paperwork and more time helping clients navigate the process.
</p>
`;

const defaultSectionServiceProviders = `
<h4 class="fs-h3 mb-3">
  Service providers
</h4>
<p class="fs-h2 text-purple">
  <strong>
    I’ve noticed Principal, Ubiquity Retirement + Savings<sup><small>®</small></sup>, Elevate by Principal<sup><small>®</small></sup>, and Wilshire Advisors LLC mentioned on this site. What are their roles and how do they fit in?
  </strong>
</p>
<p class="fs-p mb-4">
  Principal is the distributor and can be the investment manager of some of the investment options plan sponsors can use through Simply Retirement by Principal<sup><small>®</small></sup>. Ubiquity provides the plan recordkeeping and administrative services once the plan is purchased. Your client and their participating employees will also use Ubiquity’s digital platform to manage the plan. You can read more about our collaboration <a href="<LINK>">here</a>.
</p>
<p class="fs-p mb-4">
  With a Simply Retirement by Principal<sup><small>®</small></sup> plan, your client has access to Elevate by Principal, a powerful network, resource, and team of people in their corner. From data-driven insights to deep discounts on various products and services, that a business owner can use every day, Elevate by Principal helps provide what your client needs to take their business to the next level. Upon purchase of a Simply Retirement by Principal plan, your client will receive an invitation to register with Elevate by Principal which will allow them to immediately take advantage of these additional services.
</p>
<p class="fs-p mb-4">
  Wilshire Advisors LLC will be the plan’s 3(38) investment fiduciary responsible for selecting and monitoring the plan’s investment options. They’re an independent investment management firm that builds enduring relationships with clients through trust and a shared purpose that seek to improve investment outcomes for a better future. More than 47,000 plan sponsors rely on their independent expertise to help meet their fiduciary obligations.
</p>
`;

const Faq = ({
  putApplicationPanelInfo,
  applicationPanelInfo,
  sectionGeneralInformation = defaultSectionGeneralInformation,
  sectionHeader = defaultSectionHeader,
  sectionEnrollmentFees = updatedSectionEnrollmentFees,
  sectionGettingForFees = updatedSectionGettingForFees,
  sectionCreatePlan = defaultSectionCreatePlan,
  sectionServiceProviders = defaultSectionServiceProviders,
  sectionPlanFeatures = defaultSectionPlanFeatures,
  sectionOnBoardingTPA = defaultSectionOnBoarding,
  sectionSupport = defaultSectionSupport,
}) => {
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
    const values = ['faq-disclaimer-1', 'faq-disclaimer-2'];
    values.map((value) => {
      if (document.getElementById(value)) {
        document.getElementById(value).addEventListener('click', handleClick);
        return () => {
          document
            .getElementById(value)
            .removeEventListener('click', handleClick);
        };
      }
      return true;
    });
  });

  const handleClick = (e) => {
    switch (e.target.id) {
      case 'faq-disclaimer-1':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: faqDisclaimer1,
        });
        break;
      case 'faq-disclaimer-2':
        putApplicationPanelInfo({
          hasVisibility: true,
          additionalInformation: faqDisclaimer2,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="about-page mb-5">
      <HeaderSection
        image={sectionHeader.image}
        title={sectionHeader.title}
        caption={sectionHeader.caption}
        copy={sectionHeader.copy}
        alt={sectionHeader.alt}
      />

      <SectionSingleCol
        copy={sectionGeneralInformation}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />

      <SectionSingleCol
        copy={sectionServiceProviders}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />

      <SectionSingleCol
        copy={sectionEnrollmentFees}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />

      <SectionSingleCol
        copy={sectionGettingForFees}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-2 pb-1"
      />

      <SectionSingleCol
        copy={sectionCreatePlan}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />

      <SectionSingleCol
        copy={sectionPlanFeatures}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />

      <SectionSingleCol
        copy={sectionOnBoardingTPA}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-2"
      />

      <SectionSingleCol
        copy={sectionSupport}
        columns="col-12 col-lg-10 mx-auto"
        className="pt-0 pb-5"
      />

      <span className="d-none">1216092-062020</span>
    </div>
  );
};

Faq.propTypes = {
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
  sectionHeader: PropTypes.object,
  sectionGeneralInformation: PropTypes.string,
  sectionEnrollmentFees: PropTypes.string,
  sectionGettingForFees: PropTypes.string,
  sectionCreatePlan: PropTypes.string,
  sectionPlanFeatures: PropTypes.string,
  sectionOnBoardingTPA: PropTypes.string,
  sectionSupport: PropTypes.string,
  sectionServiceProviders: PropTypes.string,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
