import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { roleSpecificPath } from '../FormWithSteps/Utilities';
import { EXPERIENCE_LINKS } from '../../data/TopNavLinks';
import FormDataRedux, { selectRole } from '../../Redux/FormDataRedux';
import SignUpModalRedux from '../../Redux/SignUpModalRedux';
import { ROUTE_META } from '../../RouteMeta';

const NavList = ({
  rootRoute,
  toggleModalVisibility,
  putActiveStep,
  setOverlayVisibility,
  visibleOverlay,
  userRole,
  isFinancialHp,
  isTpa,
}) => {
  const planBtnText = (label) => {
    // If a label is provided in the configuration, use that first
    if (label) {
      return label;
    }

    // If no label is provided, determine the label based on rootRoute
    if (ROUTE_META[rootRoute] && ROUTE_META[rootRoute].route) {
      switch (ROUTE_META[rootRoute].route) {
        case ROUTE_META.employer.route:
          return 'Start a plan';
        case ROUTE_META.advisor.route:
        case ROUTE_META.tpa.route:
          return 'Create a plan';
        default:
          return 'Start a plan';
      }
    }
    return 'Start a plan'; // Default text
  };

  const getTopNavLinks = (route) => {
    const cleanedRoute = route.replace('/', ''); // Remove the leading "/"
    return EXPERIENCE_LINKS[cleanedRoute] || EXPERIENCE_LINKS.default;
  };

  const history = useHistory();

  const destination = () => {
    putActiveStep('begin');
    history.push(roleSpecificPath(userRole, rootRoute, '/plans'));
  };

  const renderLinksForExperience = () => {
    const links = getTopNavLinks(rootRoute); // Use rootRoute to determine the links

    return links.map((link) => {
      switch (link.type) {
        case 'navlink':
          return (
            <div
              className="navlist__item"
              key={link.label || link.title}
              style={{ outline: 'none', color: 'inherit', boxShadow: 'none' }}
            >
              <NavLink
                key={link.label || link.title}
                to={rootRoute + link.to}
                onClick={() => setOverlayVisibility(!visibleOverlay)}
                className="nav-link"
              >
                {link.imgSrc && (
                  <img
                    src={link.imgSrc}
                    alt={link.title}
                    className={link.className}
                    style={link.style}
                  />
                )}
                <span className="sr-only fs-p  dropdown-item-style">
                  {link.title}
                </span>
                {!link.imgSrc && <span>{link.label}</span>}
              </NavLink>
            </div>
          );
        case 'dropdown':
          return (
            <NavDropdown
              title={link.label}
              className="navlist__item"
              key={link.label}
            >
              {link.items.map((item) => {
                if (
                  item.condition === 'notFinancialHpAndNotTpa' &&
                  (isFinancialHp || isTpa)
                ) {
                  return null;
                }
                if (
                  item.conditionLabelValue ===
                    'Financial professional resources' &&
                  isTpa
                ) {
                  /* eslint-disable no-param-reassign */
                  item.label = 'Quick links';
                  /* eslint-enable no-param-reassign */
                }
                if (item.conditionLabel === 'isFinancialHp' && isFinancialHp) {
                  /* eslint-disable no-param-reassign */
                  item.label = item.conditionLabelValue;
                  /* eslint-enable no-param-reassign */
                }
                if (
                  item.condition === 'isFinancialHpOrTpa' &&
                  !(isFinancialHp || isTpa)
                ) {
                  return null;
                }
                if (item.to) {
                  return (
                    <NavDropdown.Item
                      key={item.label}
                      className="fs-p"
                      as={NavLink}
                      to={rootRoute + item.to}
                      onClick={() => setOverlayVisibility(!visibleOverlay)}
                    >
                      {item.label}
                    </NavDropdown.Item>
                  );
                }
                if (item.action) {
                  return (
                    <NavDropdown.Item
                      key={item.label}
                      className="fs-p"
                      onClick={() => {
                        if (item.action === 'toggleModalVisibility') {
                          toggleModalVisibility(true);
                        }
                        // Add other conditions for different actions if needed
                      }}
                    >
                      {item.label}
                      {item.description && (
                        <span className="dropdown-description">
                          {item.description}
                        </span>
                      )}
                    </NavDropdown.Item>
                  );
                }
                return null; // This is for safety, shouldn't be hit if data is consistent
              })}
            </NavDropdown>
          );
        case 'button':
          return (
            <button
              key={link.label}
              type="button"
              onClick={destination}
              className="navlist__btn srp-button srp-button__primary my-5 my-lg-0"
            >
              {planBtnText(link.label)}
            </button>
          );
        default:
          return null; // Safety check for unexpected type
      }
    });
  };

  return (
    <div className="navmenu__list navlist">{renderLinksForExperience()}</div>
  );
};

NavList.propTypes = {
  userRole: PropTypes.string.isRequired,
  rootRoute: PropTypes.string.isRequired,
  isFinancialHp: PropTypes.bool.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  putActiveStep: PropTypes.func.isRequired,
  isTpa: PropTypes.bool.isRequired,
  setOverlayVisibility: PropTypes.func.isRequired,
  visibleOverlay: PropTypes.bool.isRequired,
};

const mapStateToProps = (store) => ({
  userRole: selectRole(store),
  planInProgress: store.formData.planDesign.inProgress,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalVisibility: (toggle) => {
    dispatch(SignUpModalRedux.toggleModalVisibility(toggle));
  },
  putActiveStep: (UpdateStep) => {
    dispatch(FormDataRedux.putActiveStep('planDesign', UpdateStep));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavList);
