import React from 'react';
import PropTypes from 'prop-types';
import RowTpa from '../../../../common/QuickQuote/RowTpa';
import RowTpaComp from '../../../../common/QuickQuote/RowTpaComp';

const RowTpaDetails = ({
  data,
  handleQuestionChange,
  putApplicationPanelInfo,
}) => (
  <>
    <div className="flex row justify-content-center">
      <div className="col-md-5 mb-5">
        <div className="form-group">
          <h4 className="min-h-50">
            Are you working with a third party administrator?
          </h4>
          <div className="my-3 justify-content-center">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="option1"
                value={data.hasTpa.options[0].value}
                name={data.hasTpa.options[0].name}
                checked={data.hasTpa.value === data.hasTpa.options[0].value}
                onChange={(input) => {
                  handleQuestionChange(
                    data.hasTpa.name,
                    input.target.checked,
                    data.hasTpa.options[0].label,
                  );
                }}
              />
              <label className="form-check-label mr-2" htmlFor="option1">
                <strong>Yes</strong>
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                id="option2"
                value={data.hasTpa.options[1].value}
                name={data.hasTpa.options[1].name}
                checked={data.hasTpa.value === data.hasTpa.options[1].value}
                onChange={(input) => {
                  handleQuestionChange(
                    data.hasTpa.name,
                    !input.target.checked,
                    data.hasTpa.options[1].label,
                  );
                }}
              />
              <label className="form-check-label mr-2" htmlFor="option2">
                <strong>No</strong>
              </label>
            </div>
            <small>
              <button
                type="button"
                className="srp-button__link"
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    heading: 'Why are we asking?',
                    additionalInformation: `Third party administrators (TPAs) help complete key plan details. If your client is working with a TPA, you'll be asked to share this proposal with them after your portion of the plan is complete.`,
                  })
                }
              >
                Why are we asking?
              </button>
            </small>
          </div>
        </div>
        {data.hasTpa.value && (
          <div className="ml-4">
            <div className="radio-group">
              <input
                type="checkbox"
                id="tpaTbd"
                name="tpaTbd"
                className="form-check-input mt-0"
                checked={data.tpaTbd.value}
                onChange={(event) => {
                  handleQuestionChange('tpaTbd', event.target.checked);
                }}
              />
              <label htmlFor="tpaTbd" className="form-check-label">
                <strong>Yes, but I don’t have their info yet</strong>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
    {data.hasTpa.value && (
      <RowTpa data={data} handleQuestionChange={handleQuestionChange} />
    )}
    {data.hasTpa.value && (
      <RowTpaComp data={data} handleQuestionChange={handleQuestionChange} />
    )}
  </>
);

RowTpaDetails.propTypes = {
  data: PropTypes.object.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

export default RowTpaDetails;
