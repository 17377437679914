import React from 'react';
import PropTypes from 'prop-types';

const CardSection = ({
  putApplicationPanelInfo,
  financialProfessionalWhySimplyDisclaimer1,
}) => (
  <section id="card-section" className="position-relative section pt-4">
    <div className="container">
      <h3 className="fs-h3 mb-5 text-center">
        Meet Simply Retirement by Principal
        <small>
          <sup>®</sup>
        </small>{' '}
      </h3>
      <div className="row">
        <div className="col-xl-6 col-lg-7 col-md-10 mx-auto">
          <p className="fs-p">
            <strong>
              We’ve paired decades of experience in retirement solutions and
              investments from Principal with the simplicity and
              cost-effectiveness of the{' '}
              <a
                href="https://www.myubiquity.com/about"
                target="_blank"
                className="text-dark"
              >
                Ubiquity Retirement + Savings
                <small>
                  <sup>&reg;</sup>
                </small>{' '}
                online recordkeeping platform
              </a>
            </strong>
            , breaking down barriers like cost and complexity so you can help
            more small businesses offer their employees a way to save for
            retirement.
            <strong>
              That’s a big opportunity, considering less than half (48%) of
              small businesses with less than 50 employees currently don’t
              provide a{' '}
              <button
                type="button"
                className="text-underline p-0 no-border-button"
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    additionalInformation:
                      financialProfessionalWhySimplyDisclaimer1,
                  })
                }
              >
                retirement plan.
              </button>
              <sup>
                <small>*</small>
              </sup>
            </strong>{' '}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default CardSection;

CardSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  financialProfessionalWhySimplyDisclaimer1: PropTypes.any,
};
