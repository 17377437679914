import moment from 'moment';

// no restrictions on plan effective dates
// FPs + SMBs (bundled) -
// All plans should have a 21 day buffer (ex. I start a plan design today, I can only have a plan effective date of 1/23, or 21 days out from today)
// Safe Harbor: Should have a 21 day buffer except between 9/9 and 9/20. After 9/20, they can no longer select a 10/1/24 start date. After 9/20, the plan effective date should default to 1/1/25. Said differently, users can't have a safe harbor plan effective date through the end of the year if they purchase a plan after 9/20, it will default to 1/1/25 after 9/20
// Non safe harbor: Should have a 21 day delay through end of year.
// December 10 to the 16 remove 21 day buffer for non safe harbor plans

export function effectiveMinMaxDate(isSafeHarbor = false) {
  const date = moment();
  const currentYear = moment().year();
  const fiveYearsFromNow = moment().add(5, 'years').format('YYYY-MM-DD');
  const daysBeforeOctOne = moment(`${currentYear}-10-1`).subtract(22, 'days');
  const septCutOff = moment(`${currentYear}-09-20`).endOf('day');

  if (date.isBetween(daysBeforeOctOne, septCutOff) && isSafeHarbor) {
    return {
      min: moment(`${currentYear}-10-1`).format('YYYY-MM-DD'),
      max: fiveYearsFromNow,
      valueFormatted:
        'After 9/20, effective date must be at least the start of next year.',
    };
  }
  if (date.isAfter(moment(`${currentYear}-09-20`)) && isSafeHarbor) {
    return {
      min: moment(`${currentYear}-01-01`).add(1, 'years').format('YYYY-MM-DD'),
      max: fiveYearsFromNow,
      valueFormatted: 'Effective date must be at least the start of next year.',
    };
  }
  if (
    date.isBetween(
      moment(`${currentYear}-12-10`).startOf('day'),
      moment(`${currentYear}-12-16`).endOf('day'),
    ) &&
    !isSafeHarbor
  ) {
    return {
      min: date.format('YYYY-MM-DD'),
      max: fiveYearsFromNow,
      valueFormatted:
        'After December 16th, effective date must be at least the start of next year.',
    };
  }

  return {
    min: date.add(21, 'days').format('YYYY-MM-DD'),
    max: fiveYearsFromNow,
    valueFormatted:
      'Effective date must be at least 21 calendar days from the current date.',
  };
}
