import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { PurpleWrapper } from './index.styles';
import { ScrollInToView } from '../../../../common/VisibilitySensor';
import { PrincipalLogo, UbiquityLogo } from '../../../../ImagePaths';

const PurpleSection = ({
  putApplicationPanelInfo,
  businessOwnerHomeDisclaimer4,
}) => (
  <section
    className="section"
    style={{ overflow: 'hidden', marginTop: '-50px' }}
  >
    <PurpleWrapper>
      <div className="container">
        <div className="row">
          <div className="offset-md-1 order-md-1 order-2 col-lg-5 col-md-5 align-self-center my-5">
            <h3 className="fs-h3 text-white pl-0 mb-3">Backed by experience</h3>
            <p className="fs-p text-white">
              Simply Retirement by Principal
              <sup>
                <small>®</small>
              </sup>{' '}
              with PSIvet combines 80-plus years of retirement expertise from{' '}
              <button
                type="button"
                onClick={() =>
                  putApplicationPanelInfo({
                    hasVisibility: true,
                    additionalInformation: businessOwnerHomeDisclaimer4,
                  })
                }
                className="text-underline text-white p-0 no-border-button"
              >
                Principal
              </button>
              <sup>
                <small>&reg;</small>
              </sup>
              <sup>
                <small>*</small>
              </sup>{' '}
              with the digital technology and services of Ubiquity Retirement +
              Savings
              <sup>
                <small>®</small>
              </sup>
              . We’ve come together to help make retirement plans a reality for
              PSIvet members.
            </p>
            <NavLink to="/psivet/about" className="text-white">
              Learn more about our collaboration.
            </NavLink>
          </div>
          <div className="order-md-2 order-1 col-md-6">
            <ScrollInToView
              renderItem={(inView) => (
                <>
                  <div
                    className={classNames('ven ven--top d-flex m-auto', {
                      active: inView,
                    })}
                  >
                    <img
                      className="ven__img img-fluid m-auto"
                      src={PrincipalLogo}
                      alt="Principal logo"
                    />
                  </div>
                  <div
                    className={classNames('ven ven--btm d-flex m-auto', {
                      active: inView,
                    })}
                  >
                    <img
                      className="ven__img img-fluid m-auto"
                      src={UbiquityLogo}
                      alt="Ubiquity Logo"
                    />
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
    </PurpleWrapper>
  </section>
);

export default PurpleSection;

PurpleSection.propTypes = {
  putApplicationPanelInfo: PropTypes.func.isRequired,
  businessOwnerHomeDisclaimer4: PropTypes.any,
};
