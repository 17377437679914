import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormDataRedux, {
  selectApplicationPanelInfo,
} from '../../../../Redux/FormDataRedux';
import Check from '../../../../../Images/check-table.svg';

const TableFluidLarge = ({
  tableHeaders,
  tableRows,
  applicationPanelInfo,
  putApplicationPanelInfo,
}) => {
  const [selectedRow, setSelectedRow] = useState(
    tableRows[0].header.replace(/[^\w]/gi, ''),
  );
  useEffect(() => {
    const element = document.getElementById('root');
    if (applicationPanelInfo.hasVisibility) {
      document.body.classList.add('no-scroll');
      element.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
      element.classList.remove('no-scroll');
    }
  });

  return (
    <div className="table-fluid-large mb-5">
      <div className="table-fluid-large__thead">
        <div className="table-fluid-large__label"></div>
        {tableHeaders.map((tableHeader) => (
          <div
            key={tableHeader.id}
            className={classNames('table-fluid-large__col', {
              highlight: tableHeader.highlight,
            })}
          >
            <div className="heading">
              <strong dangerouslySetInnerHTML={{ __html: tableHeader.desc }} />
              {tableHeader.moreInfo && (
                <>
                  <button
                    type="button"
                    className="btn btn-link d-block d-print-none mx-auto fs-disclaimer p-0"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: tableHeader.desc,
                        additionalInformation: tableHeader.moreInfo,
                      });
                    }}
                  >
                    More info
                  </button>
                  <div
                    className="d-print-block d-none"
                    dangerouslySetInnerHTML={{
                      __html: tableHeader.moreInfo,
                    }}
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {tableRows.map((tableRow, indx) => (
        <div
          id={tableRow.header.replace(/[^\w]/gi, '')}
          key={tableRow.id}
          className={classNames('table-fluid-large__row', {
            active: selectedRow === tableRow.header.replace(/[^\w]/gi, ''),
          })}
        >
          <div
            className={classNames('table-fluid-large__label', {
              'mleft-0': !tableRows[indx - 1],
            })}
          >
            {tableRows[indx - 1] && (
              <button
                type="button"
                className="table-fluid-large__next-btn"
                onClick={() => {
                  setSelectedRow(
                    tableRows[indx - 1].header.replace(/[^\w]/gi, ''),
                  );
                }}
              >
                <i className="fa-solid fa-angle-left" aria-hidden />
                <span className="sr-only">More info</span>
              </button>
            )}
            <div className="label">
              <strong dangerouslySetInnerHTML={{ __html: tableRow.header }} />
              {tableRow.moreInfo && (
                <>
                  <button
                    type="button"
                    className="btn btn-link d-block d-print-none ml-md-0 mx-auto fs-disclaimer p-0"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        additionalInformation: `<h2 class="text-white fw-bold fs-h3">${tableRow.header}</h2> ${tableRow.moreInfo}`,
                      });
                    }}
                  >
                    More info
                  </button>
                  <div
                    className="d-print-block d-none"
                    dangerouslySetInnerHTML={{
                      __html: tableRow.moreInfo,
                    }}
                  />
                </>
              )}
            </div>
            {tableRows[indx + 1] && (
              <button
                type="button"
                className="table-fluid-large__next-btn"
                onClick={() => {
                  setSelectedRow(
                    tableRows[indx + 1].header.replace(/[^\w]/gi, ''),
                  );
                }}
              >
                <i className="fa-solid fa-angle-right" aria-hidden />
                <span className="sr-only">More info</span>
              </button>
            )}
          </div>
          {tableRow.rowDesc.map((rowDesc, idx) => (
            <div
              key={rowDesc.id}
              className={classNames('table-fluid-large__col', {
                highlight: tableHeaders[idx].highlight,
              })}
            >
              <div className="table-col scope">
                <strong
                  dangerouslySetInnerHTML={{ __html: tableHeaders[idx].desc }}
                />
                {tableHeaders[idx].moreInfo && (
                  <button
                    type="button"
                    className="btn btn-link d-block fs-disclaimer p-0 d-print-none"
                    onClick={() => {
                      putApplicationPanelInfo({
                        hasVisibility: true,
                        heading: tableHeaders[idx].desc,
                        additionalInformation: tableHeaders[idx].moreInfo,
                      });
                    }}
                  >
                    More info
                  </button>
                )}
              </div>
              <span
                className="table-col value"
                dangerouslySetInnerHTML={{ __html: rowDesc.desc }}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

TableFluidLarge.defaultProps = {
  tableHeaders: [
    {
      id: 1,
      col: 2,
      highlight: true,
      desc: `Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan`,
    },
    {
      id: 2,
      col: 2,
      desc: `401(k) plan`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>401(k) plans</strong> allow employees to set aside a portion of their compensation and also allow business owners to make contributions to the employee’s retirement plan if they choose. Loans can be allowed, providing flexibility for employees. Normally, a 401(k) is designed to help employees save for their retirement where they won't pay taxes until they withdraw the money in retirement. Roth contributions, however, are another option that allows employees to pay taxes before contributing to their retirement so they don't have to pay taxes when they withdraw the money as long as distribution requirements are met. </p>`,
    },
    {
      id: 3,
      col: 2,
      desc: `403(b) plan`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>A 403(b) plan</strong> is like a 401(k) plan; however, 403(b) plans are used by tax-exempt businesses, religious organizations, school districts, and governmental organizations. The law allows these organizations to be exempt from certain administrative processes that apply to 401(k) plans.</p>`,
    },
    {
      id: 4,
      col: 2,
      desc: `SEP IRA`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>A Simplified Employee Pension (SEP) Individual Retirement Account (IRA)</strong> allows self-employed individuals or small business owners to save toward retirement. Business owners who have employees are required to contribute on the employee’s behalf. Roth contributions (after-tax contributions that grow tax-free) and participant loans are not available.</p>`,
    },
    {
      id: 5,
      col: 1,
      desc: `SIMPLE IRA`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>A SIMPLE IRA (Savings Incentive Match Plan for Employees)</strong> is a simpler version of a 401(k) plan that works like a traditional IRA (Individual Retirement Account). Business owners are required to contribute to the plan, regardless of the employee participation and participant loans are not available. Roth contributions, however, are another option that allows employees to pay taxes before contributing to their retirement so they don't have to pay taxes when they withdraw the money.</p>`,
    },
    {
      id: 6,
      col: 1,
      desc: `Defined benefit (pension) plan`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>A defined benefit (or pension) plan</strong> is a form of retirement plan in which the business owner sets aside money for their employee while they are working to provide them with guaranteed monthly income in retirement. Money will then be paid out to the employee, usually on a monthly basis, after they have retired. Employees cannot contribute additional money. A formula is used to determine how much the business owner will contribute to a pension plan.</p>`,
    },
  ],

  tableRows: [
    {
      id: 1,
      header: `Number of employees`,
      rowDesc: [
        { id: 1, desc: `Fewer than 100`, width: `15%` },
        { id: 2, desc: `Any` },
        { id: 3, desc: `Any` },
        { id: 4, desc: `Any` },
        { id: 5, desc: `100 or less` },
        { id: 6, desc: `Any` },
      ],
    },
    {
      id: 2,
      header: `
        Employees who will benefit`,
      rowDesc: [
        { id: 1, desc: `All` },
        { id: 2, desc: `All or Select` },
        { id: 3, desc: `All or Select` },
        { id: 4, desc: `All` },
        { id: 5, desc: `All` },
        { id: 6, desc: `All or Select` },
      ],
    },
    {
      id: 3,
      header: `
        Applicable for tax-exempt and religious organizations, school districts, etc.`,
      moreInfo: `
<p class='text-white pt-3 fs-sm'>A 403(b) plan is only available to:</p>
<ul>
  <li> Employees of tax-exempt organizations established under section 501(c)(3)</li>
  <li> Employees of public school systems who are involved in the day-to-day operations of a school</li>
  <li> Employees of cooperative hospital service organizations</li>
  <li> Civilian faculty and staff of the Uniformed Services University of the Health Sciences</li>
  <li> Employees of public school systems organized by Indian tribal governments</li>
  <li> Certain ministers (see <a class='text-white' href='https://www.irs.gov/'>irs.gov</a> for criteria)</li>
</ul>
      `,
      rowDesc: [
        { id: 1, desc: `<p class="fs-h2">—</p>` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<img src=${Check} alt="check mark" />` },
        { id: 6, desc: `<img src=${Check} alt="check mark" />` },
      ],
    },
    {
      id: 4,
      header: `
        Employees can contribute <em>before</em> being taxed`,
      moreInfo: `<p class='text-white pt-3 fs-sm'>This allows employees to defer paying income taxes until they start to withdraw the money in retirement. This also lowers their taxable income for their current payroll.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<img src=${Check} alt="check mark" />` },
        { id: 6, desc: `<p class="fs-h2">—</p>` },
      ],
    },
    {
      id: 5,
      header: `
        Employees can contribute <em>after</em> being taxed`,
      moreInfo: `<p class='text-white pt-3 fs-sm'>This allows employees to pay income taxes now and not when they start to withdraw the money in retirement as long as distribution requirements are met. This could be beneficial if your employee expects to have a higher monthly income during retirement.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<img src=${Check} alt="check mark" />` },
        { id: 5, desc: `<img src=${Check} alt="check mark" />` },
        { id: 6, desc: `<p class="fs-h2">—</p>` },
      ],
    },
    {
      id: 6,
      header: `
        Business owner required to contribute`,
      moreInfo: `<p class='text-white pt-3 fs-sm'>There is a minimum amount that your business would be required to contribute to your employees’ retirement.</p>`,
      rowDesc: [
        { id: 1, desc: `<p class="fs-h2">—</p>` },
        { id: 2, desc: `<p class="fs-h2">—</p>` },
        { id: 3, desc: `<p class="fs-h2">—</p>` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<img src=${Check} alt="check mark" />` },
        { id: 6, desc: `<img src=${Check} alt="check mark" />` },
      ],
    },
    {
      id: 7,
      header: `
        Safe harbor plan option available`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>Safe harbor</strong> is just a type of 401(k) plan that allows you to bypass some of the compliance testing required by the IRS to make sure the plan is fair. In return, you’re required to help your employees save for retirement by making matching contributions to their 401(k) accounts. With safe harbor, you’ll contribute more money, but there’s less paperwork and administrative hassle. With a non-safe harbor plan, you have the option to contribute less, but you can’t bypass compliance testing required by the IRS. For more details, check out our <a href="/safeharbor-explained">safe harbor resource page</a>.</p><p><sup><small>*</small></sup>Eligible employees must be notified prior to plan start date.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<p class="fs-h2">—</p>` },
        { id: 6, desc: `<p class="fs-h2">—</p>` },
      ],
    },
    {
      id: 8,
      header: `
        Employees can borrow from account`,
      moreInfo: `<p class='text-white pt-3 fs-sm'>A loan provides the ability to withdraw funds from your retirement account early but it will need to be paid back based on plan terms. Money is taxed again when withdrawn in retirement, so those who take out a loan are subjecting themselves to double taxation.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<p class="fs-h2">—</p>` },
        { id: 6, desc: `<p class="fs-h2">—</p>` },
      ],
    },
    {
      id: 9,
      header: `
        Employees can withdraw for hardship`,
      moreInfo: `<p class='text-white pt-3 fs-sm'>A hardship withdrawal is an emergency withdrawal of funds from a retirement plan due to what the IRS calls “an immediate and heavy financial need.” There are certain criteria for why the funds are needed and their amount in order to avoid penalty, but, even if penalties are waived, the withdrawal will still be subject to standard income tax.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<p class="fs-h2">—</p>` },
        { id: 6, desc: `<p class="fs-h2">—</p>` },
      ],
    },
    {
      id: 10,
      header: `Vesting schedule`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>A vesting schedule</strong> is the time frame it takes for employees to own the assets that a business owner contributes to the employee's retirement plan. This is determined by the business owner and may be used as a retainment incentive.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<p class="fs-h2">—</p>` },
        { id: 6, desc: `<img src=${Check} alt="check mark" />` },
      ],
    },
    {
      id: 11,
      header: `Automatic enrollment`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>Automatic enrollment</strong> is a plan provision which automatically enrolls participants into the retirement plan at a specified pre-tax salary deferral percentage. This can help increase participation, simplify administration, and help employees save for retirement. Participants in <strong>the Simply Retirement by Principal<sup><small>®</small></sup> 401(k) plan</strong> can be automatically enrolled and can change their contribution details or opt out at any time.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `Optional` },
        { id: 3, desc: `Optional` },
        { id: 4, desc: `<p class="fs-h2">—</p>` },
        { id: 5, desc: `<p class="fs-h2">—</p>` },
        { id: 6, desc: `<p class="fs-h2">—</p>` },
      ],
    },
    {
      id: 12,
      header: `Rollovers`,
      moreInfo: `<p class='text-white pt-3 fs-sm'><strong>A rollover</strong> is when you transfer the money in your retirement account to a new plan or IRA. The IRS gives you 60 days from the date you receive an IRA or retirement plan distribution to roll it over to another plan or IRA. You’re allowed only one rollover per 12-month period from the same IRA. This one-rollover-per-IRA limit doesn’t apply to plan-to-plan rollovers and some other types of rollovers.</p>`,
      rowDesc: [
        { id: 1, desc: `<img src=${Check} alt="check mark" />` },
        { id: 2, desc: `<img src=${Check} alt="check mark" />` },
        { id: 3, desc: `<img src=${Check} alt="check mark" />` },
        { id: 4, desc: `<img src=${Check} alt="check mark" />` },
        { id: 5, desc: `<img src=${Check} alt="check mark" />` },
        { id: 6, desc: `<img src=${Check} alt="check mark" />` },
      ],
    },
    {
      id: 13,
      header: `Employee contribution limits`,
      rowDesc: [
        {
          id: 1,
          desc: `<button id="retirement-plans-employer-disclaimer-4" class="text-underline fs-sm p-0 no-border-button">$23,000</button>`,
        },
        {
          id: 2,
          desc: `<button id="retirement-plans-employer-disclaimer-4-2" class="text-underline fs-sm p-0 no-border-button">$23,000</button>`,
        },
        {
          id: 3,
          desc: `<button id="retirement-plans-employer-disclaimer-5" class="text-underline fs-sm p-0 no-border-button">$23,000</button>`,
        },
        {
          id: 4,
          desc: `<p class="fs-sm">N/A</p>`,
        },
        {
          id: 5,
          desc: `<button id="retirement-plans-employer-disclaimer-6" class="text-underline fs-sm p-0 no-border-button">$16,000</button>`,
        },
        {
          id: 6,
          desc: `<p class="fs-h2">—</p>`,
        },
      ],
    },
    {
      id: 14,
      header: `Investment options`,
      rowDesc: [
        {
          id: 1,
          desc: `<p class="fs-sm text-center">Any acceptable investment under the plan</p>`,
        },
        {
          id: 2,
          desc: `<p class="fs-sm text-center">Any acceptable investment under the plan</p>`,
        },
        {
          id: 3,
          desc: `<p class="fs-sm text-center">Only mutual funds and annuities</p>`,
        },
        {
          id: 4,
          desc: `<p class="fs-sm text-center">Individual stocks, bonds, mutual funds, ETFs, and others</p>`,
        },
        {
          id: 5,
          desc: `<p class="fs-sm text-center">Individual stocks, bonds, mutual funds, ETFs, and others</p>`,
        },
        {
          id: 6,
          desc: `<p class="fs-sm text-center">Any acceptable investment under the plan</p>`,
        },
      ],
    },
  ],
};

TableFluidLarge.propTypes = {
  tableHeaders: PropTypes.array,
  tableRows: PropTypes.array,
  applicationPanelInfo: PropTypes.object.isRequired,
  putApplicationPanelInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  applicationPanelInfo: selectApplicationPanelInfo(store),
});

const mapDispatchToProps = (dispatch) => ({
  putApplicationPanelInfo: (obj) => {
    dispatch(FormDataRedux.putApplicationPanelInfo(obj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableFluidLarge);
